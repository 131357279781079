import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const FoodelWebsitePage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Tackis",
    location: "Austin, TX",
    industry: "High Speed Internet",
    developmentType: "Website",
    scope: "Design and Development",
    businessObjectives:
      "To build a modern website as the face of Tackis's online presence. This website will allow customers to find out more about Tackis and check for availability in their neighborhood. The new website needs to produce more leads using current advertising methods.",
    desiredOutcomes:
      "To have a more modern website to keep up with current technologies. The new website needs to increase current online lead generation by 20%. ",
    targetMarket: "Residential internet deal shoppers",
    competition: "Comcast, Spectrum",
    competitiveAdvantage:
      "No hidden fees and simple flat rate billing structure",
    timeFrame: "2 Months",
  }

  const userStories = {
    mustHaves: [
      "A header that captures attention and explains the key competitive advantages.",
      "Sign in, sign up and customer service information available on every page",
      "After the header, lay out the main features of Tackis internet",
      "Allow users to check for availability after features",
      "Show users a pricing table with unique features for each pricing tier",
      "Testimonials section on the front page",
      "Footer with additional links for checking network status",
      "An internet page with features, pricing, signup process steps and disclaimer",
      "An about page telling the storey about Tackis and introducing the team",
      "A contact page allowing users to contact Tackis through phone, email, live chat or via a form",
      "A news page for blogging about the community news",
      "A color scheme that meshes with Tackis's current brand colors",
    ],
    niceToHaves: [
      "Network check re-design to fit with new website design",
      "My Account page re-design to fit with new website design",
      "Register page re-design to fit with new website design",
      "User backend dashboard re-design to fit with new website design",
      "Wordpress integration as a CMS for NEWS section",
      "Social media links",
    ],
    maybeLater: [
      "AI chat bot integration for FAQs",
      "Chat bot integration with Zendesk to provide customer support",
      "A knowledge base repository for common internet problems",
      "Austin local weather integration",
      "Twitter integration to showcase latest tweets and customer experiences",
      "Newsletter signup with auto-responders",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Color scheme selection based on web app",
        "Header design and development",
        "Navigation design and development",
      ],
      weeks: "Week 2",
    },
    iteration3: {
      stories: [
        "Feature section design and development",
        "Availability section design and development",
        "Pricing section design and development",
      ],
      weeks: "Week 3",
    },
    iteration4: {
      stories: [
        "Testimonials section design and development",
        "Footer design and development",
        "Page header design and development",
      ],
      weeks: "Week 4",
    },
    iteration5: {
      stories: [
        "Internet features section design and development",
        "Internet pricing section design and development",
        "Internet signup steps section design and development",
      ],
      weeks: "Week 5",
    },
    iteration6: {
      stories: [
        "Internet disclaimer section design and development",
        "About Us story section design and development",
        "About Us team section design and development",
      ],
      weeks: "Week 6",
    },
    iteration7: {
      stories: [
        "Contact Us page design and development",
        "News page design and development",
        "News post page design and development w/ Wordpress integration",
      ],
      weeks: "Week 7",
    },
    iteration8: {
      stories: [
        "User acceptance testing and bug fixes",
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 8",
    },
  }

  const technologies = {
    fullStack: false,
    icons: [
      "HTML5",
      "CSS3",
      "TailwindCSS",
      "JavaScript",
      "Typescript",
      "Gatsby",
      "Jest",
      "Git",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "Tackis had a strict 2 month deadline with a large list of stories they wanted addressed.",
      win: (
        <>
          <p>
            At Tactic Apps we always encourage our clients to think big so we
            can capture the maximum amount of stories. Tackis did just that and
            we discovered many re-design requirements that would not fit into a
            2 month release schedule.
          </p>
          <p>
            Since the Tactic Apps team uses an Agile iterative approach we were
            able to prioritize the Tackis stories to satisfy the immediate need
            of a modernized website in a 2 month time frame.
          </p>
          <p className="mb-0">
            Tackis now has the option to get further integration with re-design
            of legacy systems on the next release cycle.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Tackis was considering AI and chat bot integration to relieve their customer service teams.",
      win: (
        <>
          <p>
            At first, the Tackis team simply wanted an informational website
            that would also serve as the front end to their customer login
            portal. This began to evolve as we started created user stories
            together.
          </p>
          <p>
            The potential to reduce customer service calls through an AI enabled
            chat bot for frequently asked questions was added to the list and
            was rated high on the priority list.
          </p>
          <p className="mb-0">
            The Tactic Apps team had to show Tackis that this was not a
            possibility within the first 2 month release schedule and we all
            agreed to implement this feature properly in the second release.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "Tackis had very minimal web page content to provide the Tactic Apps team.",
      win: (
        <>
          <p>
            The original Tackis website had very little content so a straight
            port from the old website to the new one would produce substandard
            results. The Tactic Apps team suggested a substantial increase in
            information so clients will have the details they need to sign up.
          </p>
          <p className="mb-0">
            Increasing the information and image assets produced a much cleaner
            and complete website that was able to drive their business objective
            of higher lead generation.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "The Tactic Apps team switched to Typescript and TailwindCSS for more robust code.",
      win: (
        <>
          <p>
            The Tackis website was the first website we developed using
            Typescript and TailwindCSS. Making a core technology switch is
            always a challenge but was crucial in creating a faster website with
            less bugs for future development.
          </p>
          <p className="mb-0">
            The Tactic Apps team worked longer non-billable hours on this
            project and was not only able to create a robust website for Tackis,
            but also set ourselves up for future success on other projects in
            the pipeline.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "The hosting provider had lag and downtime on the final release day.",
      win: (
        <>
          <p>
            In the web and mobile development world we must be prepared to
            handle issues that are out of our immediate control. On release day
            the Tackis hosting provider had some major outages that caused lag
            and downtime.
          </p>
          <p className="mb-0">
            The Tactic Apps team jumped in to help and moved the website to a
            more stable hosting provider while the Tackis provider fixed their
            issues. We had the website up, running and stable within 45 minutes.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "1",
      hourly: "75",
      time: "2",
    },
  }

  const resultsImageNames = {
    results1: "Header",
    results2: "Features & Pricing",
    results3: "Testimonials & Footer",
    results4: "Internet Features",
    results5: "Internet How To",
    results6: "Fine Print",
    results7: "News & Blog",
    results8: "Blog Post",
    results9: "About Us",
    results10: "Contact Us",
  }

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: { eq: "work/websites/tackis/tackis-hero.jpg" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: { eq: "work/websites/tackis/devices.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: { eq: "work/websites/tackis/wireframes/header.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: { eq: "work/websites/tackis/wireframes/features.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: { eq: "work/websites/tackis/wireframes/pricing.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/websites/tackis/wireframes/testimonials.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/websites/tackis/wireframes/internet_features.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/websites/tackis/wireframes/internet_steps.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: {
              eq: "work/websites/tackis/wireframes/internet_notes.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe8: file(
            relativePath: { eq: "work/websites/tackis/wireframes/news.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe9: file(
            relativePath: { eq: "work/websites/tackis/wireframes/about.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe10: file(
            relativePath: { eq: "work/websites/tackis/wireframes/contact.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-header.jpeg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-feature-price.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-testimonials-footer.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-internet-features.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-internet-steps.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-internet-note.jpg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-news.jpeg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results8: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-post.jpeg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results9: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-about.jpeg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          results10: file(
            relativePath: {
              eq: "work/websites/tackis/tackis-results-contact.jpeg"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Tackis Website Case Study"
            keywords={[`tackis website case study`]}
            description="Tackis is a residential ISP that provides high speed and low cost internet services without any up-charges. Their website waws built with Gatsby & TailwindCSS"
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Website Case study"
            subTitle="Tackis"
            overlay="light"
            pageContext={pageContext}
            location={location}
            crumbLabel="Tackis"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default FoodelWebsitePage
